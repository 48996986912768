import React, {useRef, useState} from 'react';
import Search from "./search";
import Topics from "./topics";
import EmailCapture from "../EmailCapture";
import noResults from "../../images/tronpedia/topic-pages/no-results-bull.png"
import Featured from "./featured";
import Nft from "../../images/tronpedia/carousel/nft.png";
import Defi from "../../images/tronpedia/carousel/defi.png";
import Blockchain from "../../images/tronpedia/carousel/blockchain.png";
import Crypto from "../../images/tronpedia/carousel/crypto.png";
import Gamefi from "../../images/tronpedia/carousel/gamefi.png";
import Stablecoin from "../../images/tronpedia/carousel/stablecoin.png";
import Web3 from "../../images/tronpedia/carousel/web3.png";

const CategoryLayout = (data) => {
    const currentIndex = useRef(0);
    let pageData = data.pageData;
    let allArticles = data.data.allWpTronpediaArticle.edges;


    let [articles, setArticles] = useState(allArticles.slice(0, 4));

    const getImage = (category) => {
        let upper = category.toUpperCase();
        switch (upper) {
            case "NFT":
                return Nft;
            case "DEFI":
                return Defi;
            case "BLOCKCHAIN":
                return Blockchain;
            case "CRYPTO":
                return Crypto;
            case "GAMEFI":
                return Gamefi;
            case "STABLECOIN":
                return Stablecoin;
            default:
                return Web3;
        }
    }


    const updatePaginationIndex = (index) => {
        while (index % 4 !== 0) {
            index--;
        }
        currentIndex.current = index;
        setArticles(allArticles.slice(index, index + 4));
    }


    return (
        <div className="tronpedia">
            <div className="row no-gutters mt-5 p-lg-5 p-2">
                <div className="col-md-6 col-12 align-items-center">
                    <div className="tronpedia-header text-uppercase"><h1>{pageData.title}</h1></div>
                    <div className="tronpedia-content mt-3">{pageData.description}</div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 align-items-center">
                    <div className="tronpedia-tronbull text-center">
                        <img src={pageData.img} className="tronpedia-tronbull-icon img-fluid"
                             alt={"tron bull " + pageData.title}/>
                    </div>
                </div>
            </div>
            <div className="row no-gutters mt-5 p-lg-5 p-2">
                {!pageData.title.includes("Search") && <h3 className="">TRONPEDIA > {pageData.title}</h3>}
                <div className="col-12">
                    <Search location={data.location}/>
                </div>
            </div>

            <div className="row no-gutters mt-5 p-lg-5 p-2">
                <h3 className="">POPULAR TOPICS</h3>
                <div className="col-12">
                    <Topics/>
                </div>
            </div>

            <div className="row no-gutters my-5 p-lg-5 p-2">
                <h3 className="">{pageData.title.includes("Search") ? "Results" : pageData.title}</h3>
                <div className="d-flex justify-content-center justify-content-lg-start flex-wrap w-100">
                    {articles.length > 0 && articles.map(((node, index) => {
                        let article = node.node.tronpedia_article_info;
                        return <a href={"/tronpedia/" + node.node.slug} id={"tronpedia-category-article-" + node.node.title}
                                  key={"tronpedia-category-article-" + node.node.title + "-" + index}
                                  className="mt-5 w-100">
                            <div className="tronpedia-category-article row no-gutters">
                                <div
                                    className="tronpedia-category-article-img col-0 col-sm-2 align-items-center d-flex justify-content-center p-5">
                                    <img src={getImage(node.node.tronpedia_article_info.category)} alt={node.node.title}
                                         className="img-fluid"/>
                                </div>
                                <div className="align-items-center col-12 col-sm-8 offset-0 offset-sm-1">
                                    <h4 className="mt-1">{node.node.title}</h4>
                                    <p className="mt-1">{article.shortDescription}</p>
                                    <div
                                        className="d-block d-md-none float-right tronpedia-category-article-see-more">See
                                        More &gt;</div>
                                </div>
                            </div>
                        </a>
                    }))}

                </div>
            </div>

            <div className="w-100 my-5 d-flex justify-content-center pb-5">
                {
                    allArticles.length === 0 && (
                        <div className={"w-100 text-center tronpedia-category-no-results"}>
                            <img className="img-fluid" src={noResults} alt="tron bull no search results"/>
                            <h4>Please, Try Again</h4>
                            <p>Your Search Does Not Match Our Records</p>
                        </div>
                    )
                }
                {
                    currentIndex.current > 4 && (
                        <div key={"pagination-buttons-first-page"}
                             onClick={function () {
                                 updatePaginationIndex(0)
                             }}
                             onKeyDown={function (e) {
                                 if (e.key === "Enter") {
                                     updatePaginationIndex(0)
                                 }
                             }}
                             role="button"
                             aria-label={"View Articles Index" + 0}
                             tabIndex="0"
                             className={"m-2 cursor-pointer tronpedia-category-article-pagination"}>
                            <p className="m-0 text-center">&lt;</p>
                        </div>
                    )
                }
                {
                    allArticles.length > 4 && allArticles.map(((setOfArticles, index) => {
                        if (index % 4 === 0) {
                            let isActive = currentIndex.current === index ? ' tronpedia-category-article-pagination-active ' : ' ';
                            let isShown = (currentIndex.current > index - 8) && (currentIndex.current < index + 8) ? ' ' : "d-none";

                            if (isShown === "d-none" && currentIndex.current < 8) {
                                if (currentIndex.current < 12 && index < 12) {
                                    isShown = " ";
                                }
                            }
                            if (isShown === "d-none" && currentIndex.current >= (allArticles.length - 4)) {
                                if (currentIndex.current - index === 8) {
                                    isShown = " ";
                                }
                            }
                            return <div key={"pagination-buttons-" + index}
                                        onClick={function () {
                                            updatePaginationIndex(index)
                                        }}
                                        onKeyDown={function (e) {
                                            if (e.key === "Enter") {
                                                updatePaginationIndex(index)
                                            }
                                        }}
                                        role="button"
                                        aria-label={"View Articles Index" + index}
                                        tabIndex="0"
                                        className={"m-2 pl-1 cursor-pointer tronpedia-category-article-pagination " + isActive + isShown}>
                                <p className="m-0 text-center">{(index / 4) + 1}</p>
                            </div>
                        } else return <></>
                    }))
                }
                {
                    currentIndex.current + 8 <= allArticles.length - 1 && (
                        <div key={"pagination-buttons-last-page"}
                             onClick={function () {
                                 updatePaginationIndex(allArticles.length - 1)
                             }}
                             onKeyDown={function (e) {
                                 if (e.key === "Enter") {
                                     updatePaginationIndex(allArticles.length - 1)
                                 }
                             }}
                             role="button"
                             aria-label={"View Articles Index" + allArticles.length - 1}
                             tabIndex="0"
                             className={"m-2 cursor-pointer tronpedia-category-article-pagination"}>
                            <p className="m-0 text-center">></p>
                        </div>
                    )
                }
            </div>

            <Featured/>


            <EmailCapture/>


        </div>
    )
}

export default CategoryLayout

